<template>
  <div>
    <Toast position="top-right" />

    <div class="card">
      <div class="card-body p-0 py-4">
        <div class="aut-area-exibir">
          <div class="configuracao-menu-container">
            <b-tabs>
              <b-tab
                v-for="canal in canais_disponiveis"
                :key="canal.value"
                :title="canal.text"
                class="p-4"
              >
                <div class="header-menus-container">
                  <h3>
                    Configurações do Menu -
                    <span style="color: #ffa800">{{ canal.text }}</span>
                  </h3>
                  <div class="botoes-container">
                    <b-button
                      variant="success mr-2"
                      @click="salvarConfiguracaoMenu"
                    >
                      Salvar Alterações
                    </b-button>
                    <b-button
                      class="btn-danger"
                      variant="primary"
                      @click="descartarAlteracoes"
                      >Descartar</b-button
                    >
                  </div>
                </div>
                <br />

                <div class="flexer-container">
                  <div class="form-group w-100">
                    <label for="titulo">Mensagem Inicial do Chatbot</label>
                    <b-form-textarea
                      type="text"
                      class="form-control"
                      id="titulo"
                      placeholder="Olá! Eu sou um assistente virtual, digite o número correspondente ao serviço que você precisa de ajuda:"
                      v-model="menu[canal.value].cmc_msg_menu"
                      rows="5"
                    />
                  </div>
                  <div class="form-group w-100">
                    <label for="ordem">Mensagem Para Resposta Inválida</label>
                    <b-form-textarea
                      type="text"
                      class="form-control"
                      id="ordem"
                      placeholder="Eu não estou conseguindo entender sobre o quê você gostaria de conversar 🙁. Para ver todos os serviços oferecidos, digite a palavra *menu*"
                      v-model="menu[canal.value].cmc_msg_item_invalido"
                      rows="5"
                    />
                  </div>
                </div>
                <div class="flexer-container">
                  <div class="form-group w-100">
                    <label for="ordem">Mensagem Para Fim de Atendimento</label>
                    <b-form-textarea
                      type="text"
                      class="form-control"
                      id="cmc_msg_fim_atendimento"
                      placeholder="Fim de atendimento, obrigado pela preferência..."
                      v-model="menu[canal.value].cmc_msg_fim_atendimento"
                      rows="5"
                    />
                  </div>
                  <div class="form-group w-100">
                    <label for="ordem">Mensagem de Erro com Consultas</label>
                    <b-form-textarea
                      type="text"
                      class="form-control"
                      id="cmc_msg_fim_atendimento"
                      placeholder="Erro interno, tente novamente."
                      v-model="menu[canal.value].cmc_msg_erro"
                      rows="5"
                    />
                  </div>
                </div>
                <div class="w-100 flexer-container">
                  <div class="form-group w-100">
                    <label for="ordem">N° Max de Resultados em Consultas</label>
                    <input
                      type="text"
                      class="form-control"
                      id="cmc_qtd_max_resultados"
                      placeholder="10"
                      v-model="menu[canal.value].cmc_qtd_max_resultados"
                    />
                  </div>
                  <div class="form-group w-100">
                    <label for="ativo">Ativo</label>
                    <select
                      class="form-control"
                      id="ativo"
                      v-model="menu[canal.value].cmc_ativo"
                    >
                      <option :value="true">Sim</option>
                      <option :value="false">Não</option>
                    </select>
                  </div>
                  <div class="form-group w-100">
                    <label for="ativo">Replicar Conversa em Tempo Real</label>
                    <select
                      class="form-control"
                      id="ativo"
                      v-model="
                        menu[canal.value].bool_replicar_conversa_tempo_real
                      "
                    >
                      <option :value="true">Sim</option>
                      <option :value="false">Não</option>
                    </select>
                  </div>
                  <div
                    class="form-group w-100"
                    v-if="verificarRecursoAtivo('IA_BOT')"
                  >
                    <label for="ativo">Ativar Inteligência Artificial</label>
                    <select
                      class="form-control"
                      id="ativo"
                      v-model="menu[canal.value].cmc_inteligencia_artificial"
                    >
                      <option :value="true">Sim</option>
                      <option :value="false">Não</option>
                    </select>
                  </div>
                </div>
                <div class="w-100 flexer-container">
                  <div class="form-group w-100">
                    <label for="ativo">Tempo de Inatividade (em minutos)</label>
                    <input
                      class="form-control"
                      id="cmc_tempo_inatividade"
                      placeholder="15"
                      v-model="menu[canal.value].cmc_tempo_inatividade"
                      type="number"
                      min="2"
                      @change="
                        verificarTempoInatividade(
                          menu[canal.value].cmc_tempo_inatividade
                        )
                      "
                    />
                  </div>
                  <div class="form-group w-100">
                    <label for="ativo">Ação em Caso de Inatividade</label>
                    <b-form-select
                      :options="acao_inatividade_options"
                      v-model="menu[canal.value].cmc_acao_inatividade"
                      class="col"
                    ></b-form-select>
                  </div>
                  <div
                    class="form-group w-100"
                    v-b-tooltip.hover
                    title="Caso o usuário envie uma imagem em um estágio de mensagem a inteligência artificial irá interpreta-la e transformar em texto."
                  >
                    <label for="ativo"
                      >Inteligência Artificial Interpreta Imagens</label
                    >
                    <select
                      class="form-control"
                      id="ativo"
                      v-model="
                        menu[canal.value].cmc_inteligencia_artificial_imagem
                      "
                    >
                      <option :value="true">Sim</option>
                      <option :value="false">Não</option>
                    </select>
                  </div>
                  <div
                    class="form-group w-100"
                    v-b-tooltip.hover
                    title="Caso o usuário envie um áudio a inteligência artificial irá interpreta-lo e transformar em texto."
                  >
                    <label for="ativo"
                      >Inteligência Artificial Interpreta Áudios</label
                    >
                    <select
                      class="form-control"
                      id="ativo"
                      v-model="
                        menu[canal.value].cmc_inteligencia_artificial_audio
                      "
                    >
                      <option :value="true">Sim</option>
                      <option :value="false">Não</option>
                    </select>
                  </div>
                </div>

                <div class="w-100 flexer-container">
                  <div class="form-group w-100">
                    <label for="ativo">Pré-Fluxo</label>
                    <b-form-select
                      :options="acao_inatividade_options"
                      v-model="menu[canal.value].cmc_prefluxo"
                      class="col"
                    ></b-form-select>
                  </div>
                  <div class="form-group w-100">
                    <label for="ativo">Ação Fora do Expediente</label>
                    <b-form-select
                      :options="acao_inatividade_options"
                      v-model="menu[canal.value].cmc_fluxo_fora_expediente"
                      class="col"
                    ></b-form-select>
                  </div>
                </div>

                <div class="w-100 flexer-container">
                  <div class="form-group w-100">
                    <label for="cmc_qtd_erro_maximo"
                      >Quantidade Máxima de Erros no Menu Principal</label
                    >
                    <b-form-select
                      :options="qtd_erros_options"
                      v-model="menu[canal.value].cmc_qtd_erro_maximo"
                      class="col"
                      id="cmc_qtd_erro_maximo"
                    ></b-form-select>
                  </div>
                  <div class="form-group w-100">
                    <label for="ativo"
                      >Ação em Caso de Quantidade Máxima de Erros
                      Excedida</label
                    >
                    <b-form-select
                      :options="acao_inatividade_options"
                      v-model="menu[canal.value].tratamento_erro_fluxo_id"
                      class="col"
                    ></b-form-select>
                  </div>
                </div>

                <hr />
                <div class="w-100 flexer-container">
                  <div class="form-group w-50">
                    <label for="ativo">
                      Modo Privado (vírgula para separar) - para modo público
                      deixar este campo vazio
                    </label>

                    <Chips
                      v-model="menu[canal.value].cmc_arr_numeros"
                      separator=","
                      class="w-100 mt-3"
                    >
                      <template #chip="slotProps">
                        <div class="lista-teste">
                          <i
                            class="pi pi-user-plus"
                            style="font-size: 18px"
                          ></i>
                          <span>{{ slotProps.value }}</span>
                        </div>
                      </template>
                    </Chips>
                  </div>

                  <div class="form-group w-50">
                    <label for="ativo">
                      Desativar para (vírgula para separar) - chatbot não
                      responderá os contatos listados
                    </label>

                    <Chips
                      v-model="menu[canal.value].cmc_arr_numeros_ignorar"
                      separator=","
                      class="w-100 mt-3"
                    >
                      <template #chip="slotProps">
                        <div class="lista-negra">
                          <i
                            class="pi pi-user-minus"
                            style="font-size: 18px"
                          ></i>
                          <span>{{ slotProps.value }}</span>
                        </div>
                      </template>
                    </Chips>
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Swal from "sweetalert2";
import Chips from "primevue/chips";

import ApiService from "@/core/services/api.service";
import API_LINKS from "../automacao/api.links";

export default {
  components: {
    Toast: () => import("primevue/toast"),
    Chips
  },
  data() {
    return {
      canais_possiveis: [
        { value: "AGENTE_SITE_WIDGET", text: "Widget" },
        { value: "WHATSAPP_OFICIAL", text: "WhatsApp (Oficial)" },
        { value: "INSTAGRAM", text: "Instagram" }
      ],
      qtd_erros_options: [
        { value: 1, text: "1" },
        { value: 2, text: "2" },
        { value: 3, text: "3" },
        { value: 4, text: "4" },
        { value: 5, text: "5" },
        { value: 10, text: "10" }
      ],
      recursos: [],
      canais_disponiveis: [{ value: "PRINCIPAL", text: "Principal" }],
      canais_obj_enviar: {
        principal: {},
        whatsapp: {},
        instagram: {},
        agente_site: {}
      },
      menuConfigsIniciais: {},
      acao_inatividade_options: [{ value: null, text: "Nenhuma" }],
      prefluxo_options: [{ value: null, text: "Nenhum pré-fluxo" }],
      menu: {
        PRINCIPAL: {
          cmc_canal: null,
          cmc_msg_menu: "",
          cmc_msg_item_invalido: "",
          cmc_msg_erro: "",
          cmc_msg_fim_atendimento: "",
          cmc_qtd_max_resultados: 20,
          cmc_ativo: true,
          cmc_arr_numeros: [],
          cmc_arr_numeros_ignorar: [],
          cmc_acao_inatividade: null,
          cmc_tempo_inatividade: 15,
          cmc_qtd_erro_maximo: 3,
          tratamento_erro_fluxo_id: null,
          cmc_prefluxo: null,
          cmc_fluxo_fora_expediente: null,
          cmc_msg_erro_redirecionamento: ""
        },
        WHATSAPP_OFICIAL: {
          cmc_canal: "AW",
          cmc_msg_menu: "",
          cmc_msg_item_invalido: "",
          cmc_msg_erro: "",
          cmc_msg_fim_atendimento: "",
          cmc_qtd_max_resultados: 20,
          cmc_ativo: true,
          cmc_arr_numeros: [],
          cmc_arr_numeros_ignorar: [],
          cmc_acao_inatividade: null,
          cmc_tempo_inatividade: 15,
          cmc_qtd_erro_maximo: 3,
          tratamento_erro_fluxo_id: null,
          cmc_prefluxo: null,
          cmc_fluxo_fora_expediente: null,
          cmc_msg_erro_redirecionamento: ""
        },
        INSTAGRAM: {
          cmc_canal: "AI",
          cmc_msg_menu: "",
          cmc_msg_item_invalido: "",
          cmc_msg_erro: "",
          cmc_msg_fim_atendimento: "",
          cmc_qtd_max_resultados: 20,
          cmc_ativo: true,
          cmc_arr_numeros: [],
          cmc_arr_numeros_ignorar: [],
          cmc_acao_inatividade: null,
          cmc_tempo_inatividade: 15,
          cmc_qtd_erro_maximo: 3,
          tratamento_erro_fluxo_id: null,
          cmc_prefluxo: null,
          cmc_fluxo_fora_expediente: null,
          cmc_msg_erro_redirecionamento: ""
        },
        AGENTE_SITE_WIDGET: {
          cmc_canal: "AS",
          cmc_msg_menu: "",
          cmc_msg_item_invalido: "",
          cmc_msg_erro: "",
          cmc_msg_fim_atendimento: "",
          cmc_qtd_max_resultados: 20,
          cmc_ativo: true,
          cmc_arr_numeros: [],
          cmc_arr_numeros_ignorar: [],
          cmc_acao_inatividade: null,
          cmc_tempo_inatividade: 15,
          cmc_qtd_erro_maximo: 3,
          tratamento_erro_fluxo_id: null,
          cmc_prefluxo: null,
          cmc_fluxo_fora_expediente: null,
          cmc_msg_erro_redirecionamento: ""
        }
      },
      menuCadastrar: {
        menuFluxo: "",
        menuTitulo: "",
        menuOrdem: 1,
        menuAtivo: false
      },
      menusChatbot: [],
      menuConfiguracao: [],
      porPagina: 10,
      paginaAtual: 1,
      fluxos: [],
      campos: [
        { key: "chatbot_menu_item_id", label: "ID" },
        { key: "cfl_titulo", label: "Nome" },
        { key: "descricao", label: "Descrição" },
        { key: "data_criacao", label: "Data de Criação" },
        { key: "data_atualizacao", label: "Data de Atualização" },
        { key: "acoes", label: "Ações" }
      ]
    };
  },
  methods: {
    verificarTempoInatividade(tempo_inatividade) {
      if (tempo_inatividade <= 5) {
        this.$toast.add({
          severity: "warn",
          summary: "Atenção",
          detail:
            "Devido a latência de envio e recebimento de mensagens, tempos de timeout abaixo de 5 minutos podem ocasionar comportamento inesperado.",
          life: 5000
        });
      }
    },
    recursosDeCanaisValidos(recursos) {
      this.recursos = recursos;
      const recursos_validos = [];
      recursos.forEach((recurso) => {
        const obj = this.canais_possiveis.find((v) => v.value === recurso);
        if (obj) {
          recursos_validos.push(obj);
        }
      });
      this.canais_disponiveis =
        this.canais_disponiveis.concat(recursos_validos);
    },
    pegarRecursosDaConta() {
      ApiService.get(API_LINKS.recursosAtivos).then((res) => {
        this.recursosDeCanaisValidos(res.data.data.map((v) => v.rec_codigo));
      });
    },
    descartarAlteracoes() {
      if (!this.houveAlgumaMudanca) {
        return;
      }
      Swal.fire({
        title: "Descartar Alterações?",
        text: "Você tem certeza que deseja descartar as alterações?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, descartar!"
      }).then((result) => {
        if (result.value) {
          this.menu = this.menuConfigsIniciais;
        }
      });
    },
    salvarConfiguracaoMenu() {
      Swal.fire({
        title: "Salvar Alterações?",
        text: "Você tem certeza que deseja salvar as alterações?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, salvar!"
      }).then((result) => {
        if (result.value) {
          const payload = {
            configuracao: []
          };

          for (const key in this.menu) {
            if (this.menu.hasOwnProperty(key)) {
              const element = this.menu[key];
              if (element.cmc_tempo_inatividade == null) {
                element.cmc_tempo_inatividade = 15;
              }
              if (element.cmc_tempo_inatividade <= 1) {
                Swal.fire({
                  title: "Erro!",
                  text: "O tempo de mínimo inatividade deve ser maior que 1 minuto.",
                  icon: "error",
                  confirmButtonText: "Ok"
                });
                return;
              }
              if (element.cmc_qtd_erro_maximo == null) {
                element.cmc_qtd_erro_maximo = 3;
              }
              payload.configuracao.push(element);
            }
          }

          ApiService.post(API_LINKS.chatbot_menu_configuracao, payload).then(
            () => {
              Swal.fire(
                "Salvo!",
                "As alterações foram salvas com sucesso.",
                "success"
              );
              this.$emit("menuSalvoComSucesso");
            }
          );
        }
      });
    },
    pegarConfiguracoesMenu() {
      ApiService.get(API_LINKS.chatbot_menu).then((res) => {
        if (res.data?.sucesso === false) {
          Swal.fire({
            title: "Erro!",
            text: res.data.errors,
            icon: "error",
            confirmButtonText: "Ok"
          });
          return;
        }
        const menuConfigs = {};
        res.data.menuConfiguracao.map((config) => {
          if (config.cmc_canal === "AS") {
            menuConfigs.AGENTE_SITE_WIDGET = config;
          } else if (config.cmc_canal === "AW") {
            menuConfigs.WHATSAPP_OFICIAL = config;
          } else if (config.cmc_canal === "AI") {
            menuConfigs.INSTAGRAM = config;
          } else if (config.cmc_canal === null) {
            menuConfigs.PRINCIPAL = config;
          }
        });
        this.menu = menuConfigs;
        this.menuConfigsIniciais = JSON.parse(JSON.stringify(this.menu));
      });
    },
    pegarFluxos() {
      ApiService.get(API_LINKS.chatbot_listar_fluxos).then((res) => {
        this.fluxos = res.data.fluxos.map((fluxo) => {
          return {
            ...fluxo,
            value: fluxo.chatbot_fluxo_id,
            text: fluxo.cfl_titulo
          };
        });
        this.acao_inatividade_options = this.acao_inatividade_options.concat(
          this.fluxos
        );
        this.prefluxo_options = this.prefluxo_options.concat(this.fluxos);
      });
    },
    verificarRecursoAtivo(recurso) {
      return this.recursos.includes(recurso);
    }
  },

  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Chatbot"
      },
      {
        title: "Opções",
        route: "/painel/chatbot/opcoes"
      }
    ]);

    this.pegarRecursosDaConta();
    this.pegarFluxos();
    this.pegarConfiguracoesMenu();
  },
  computed: {
    houveAlgumaMudanca() {
      return (
        JSON.stringify(this.menu) != JSON.stringify(this.menuConfigsIniciais)
      );
    }
  }
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300&display=swap");

.b-button-custom {
  width: 142px;
}

.aut-body {
  border-radius: 5px;
  background-color: #fff;
}

.aut-area-exibir {
  display: flex;
  height: 100%;
  width: 100%;
}

.aut-header {
  height: 70px;
  width: 100%;
}

.aut-camp-area {
  width: 100%;
  min-height: 500px;
}

.container-dashboard {
  border-radius: 15px;
  background-color: #fff;
  padding: 20px 10px 20px 10px;
  margin-bottom: 15px;
}

.campo-estatistica {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}
.dropdown-form {
  padding: 3px 0px;
  width: 170px;
  height: 45px;
}

.dropdown-form:hover {
  background-color: rgb(247, 247, 247);
}

.button-container {
  border: 1px solid #e4e6ef;
  outline: none;
  color: #e3e4ed;
  background-color: transparent;
  border-radius: 0.42rem !important;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 38px;
}
.button-container.btn i {
  padding: 0 !important;
}

::v-deep .p-chips .p-chips-multiple-container {
  width: 100% !important;
}
::v-deep .p-chips-multiple-container {
  flex-wrap: wrap !important;
  gap: 0.5rem !important;
}

.configuracao-menu-container {
  width: 100%;

  .header-menus-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    h3 {
      margin: 0;
    }
    .botoes-container {
      display: flex;
      justify-content: space-between;
    }
  }

  .flexer-container {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    min-height: 100% !important;
  }

  .flexer-col {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100% !important;

    > .form-group:first-child {
      margin-bottom: 0 !important;
    }
  }

  .lista-teste span {
    color: rgb(75, 112, 190);
    font-weight: bold;
  }
  .lista-teste i {
    color: blue;
    margin-right: 10px;
  }

  .lista-negra span {
    color: rgb(226, 77, 77);
    font-weight: bold;
  }
  .lista-negra i {
    color: red;
    margin-right: 10px;
  }
}
</style>
